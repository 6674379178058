<template src="./userQR.html"></template>

<script>
// import axios from 'axios';
export default {
  name: 'home',
  components: {},
  data() {
    return {
      quotes: null,
    };
  },
  // created(){
  //   axios
  //   .get('https://soco-loyalty-feature-api.sociolabs.io/beauty-quotes?limit=1&sort=-created_at')
  //   .then(res => {
  //   const data = res.data.data;
  // //   console.log(data);
  //   this.quotes = data;
  // });

  // },
  computed: {
    user() {
      return this.$store.state.QRcode.user;
    },
  },
  created() {
    if (!this.$store.state.QRcode.isLoggedIn) {
      this.$store.dispatch('me').then((response) => {
        if (response) {
          setTimeout(() => {
            this.$router.push('/recommendation/home');
          }, 3200);
        } else {
          this.$router.push('/recommendation/splash');
        }
      });
    } else {
      setTimeout(() => {
        this.$router.push('/recommendation/home');
      }, 3200);
    }
  },
};
</script>

<style lang="scss" scoped>
@import 'userQR';
</style>
